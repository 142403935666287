import PageTransition from "./PageTransition";
import Header from "./home-components/Header";
import WhatIDO from "./home-components/WhatIDo";
import Bio from "./home-components/Bio";
import MyWorks from "./home-components/MyWorks";

export default function Home() {
  return (
    <PageTransition>
      <Header />
      <WhatIDO />
      <Bio />
      <MyWorks />
    </PageTransition>
  );
}
