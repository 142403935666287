import { Link } from "react-router-dom";
import Hamburger from "./Hamburger";
import logo2 from "../../images/logo-2.PNG";

export default function Navbar() {
  return (
    <div className="navbar">
      <Link className="mobile-logo" to="/">
        <img src={logo2} alt="logo" style={{ width: "30px" }} />
      </Link>
      <div className="email">
        <a
          className="text-link orange-hover"
          href="mailto:andreaslamperis@gmail.com"
        >
          <i className="fa fa-envelope"></i>
          andreaslamperis@gmail.com
        </a>
      </div>
      <div className="nav-links">
        <ul>
          <li>
            <Link className="text-link orange-hover" to="/work">
              <i className="fa fa-file"></i>
              <span>Work</span>
            </Link>
          </li>
          <li>
            <Link className="text-link orange-hover" to="/resume">
              <i className="fa fa-user"></i>
              <span>Resume</span>
            </Link>
          </li>
          <li>
            <Link className="text-link orange-hover" to="/testimonials">
              <i className="fa fa-book"></i>
              <span>Testimonials</span>
            </Link>
          </li>
        </ul>
        <Hamburger />
      </div>
    </div>
  );
}
