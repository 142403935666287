import PageTransition from "./PageTransition";
import { Link } from "react-router-dom";
import tindog from "../../images/worksImages/a1.PNG";
import rememberThat from "../../images/worksImages/a2.PNG";
import guessMyNumber from "../../images/worksImages/a3.PNG";
import pigGame from "../../images/worksImages/a4.PNG";
import scroll from "../../images/worksImages/a5.PNG";
import simonGame from "../../images/worksImages/a6.PNG";

export default function Work() {
  return (
    <PageTransition>
      <section id="my-works-page">
        <div className="my-works-wrapper">
          <h1 className="my-works-h1" style={{ fontSize: "1.5rem" }}>
            Web Applications
          </h1>
          <div className="divider"></div>
          {/* WEB APPLICATIONS */}
          <div className="web-apps-wrapper">
            <div className="web-apps">
              <a
                href="https://andreaslamperis.github.io/TinDog/"
                target="_blank"
              >
                <div className="web-app web-app-1">
                  <img src={tindog} />
                  <div className="overlay">
                    <div>tindog</div>
                  </div>
                </div>
              </a>
              <a
                href="https://andreaslamperis.github.io/RememberThat/"
                target="_blank"
              >
                <div className="web-app web-app-2">
                  <img src={simonGame} />
                  <div className="overlay">
                    <div>Remember That</div>
                  </div>
                </div>
              </a>
              <a
                href="https://andreaslamperis.github.io/Simon_Game/"
                target="_blank"
              >
                <div className="web-app web-app-3">
                  <img src={rememberThat} />
                  <div className="overlay">
                    <div>Simon Game</div>
                  </div>
                </div>
              </a>
              <a
                href="https://andreaslamperis.github.io/Scroll-Animation/"
                target="_blank"
              >
                <div className="web-app web-app-4">
                  <img src={guessMyNumber} />
                  <div className="overlay">
                    <div>Scroll Animation</div>
                  </div>
                </div>
              </a>
              <a
                href="https://andreaslamperis.github.io/Pig_Game/"
                target="_blank"
              >
                <div className="web-app web-app-5">
                  <img src={pigGame} />
                  <div className="overlay">
                    <div>Pig Game</div>
                  </div>
                </div>
              </a>
              <a
                href="https://andreaslamperis.github.io/Guess-My-Number/"
                target="_blank"
              >
                <div className="web-app web-app-6">
                  <img src={scroll} />
                  <div className="overlay">
                    <div>Guess My Number</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          {/* RESUME DOWNLOAD */}
          <div className="resume-preview-wrapper">
            <p>Want to know about my profession? See my resume 👉</p>
            <Link to="/resume">
              <div className="resume-preview">RESUME</div>
            </Link>
          </div>
        </div>
      </section>
    </PageTransition>
  );
}
