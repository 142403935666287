import image from "../../../images/use-this.jpg";

import { ScrollTrigger } from "gsap/all";
import { gsap } from "gsap";
import { useEffect, useRef } from "react";
gsap.registerPlugin(ScrollTrigger);

export default function Bio() {
  const bio = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      bio.current,
      {
        y: 300,
      },
      {
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: bio.current,
          scrub: 1,
          start: "-60% center",
          end: "-5% center",
        },
      }
    );
  }, []);

  return (
    <section id="bio" ref={bio}>
      <div className="bio-wrapper link-text">
        {/* TOP SIDE */}
        <div className="bio-top">
          <div className="bio-top-left">
            <img src={image} />
          </div>

          <div className="bio-top-right">
            <p>
              <span>
                <i className="fa fa-coffee"></i>
              </span>{" "}
              They say coffee and code are the perfect match, and I couldn't
              agree more. Whether it's debugging with a latte or styling with a
              cappuccino, my trusty sidekick keeps me caffeinated and my code
              perky. Together, we conquer the digital realm, one sip at a time!
            </p>
            <br />
            <p>
              <span>
                <i className="fa fa-gamepad"></i>
              </span>{" "}
              Video games are my ultimate escape, and you will probably find me
              somewhere in{" "}
              <span style={{ fontWeight: "bold" }}>"The Last Of Us"</span>{" "}
              universe, deeply moved by this masterpiece.
            </p>
            <br />
            <p>
              <span>
                <i className="fa fa-pencil"></i>
              </span>{" "}
              Ah yes.. I'm also a Sketching Enjoyer
            </p>
            <br />
            <p>
              <span style={{ fontWeight: "bold" }}>Fun Fact:</span> I used to be
              terrible at photography. But in 2024, I decided to tackle this
              challenge head-on by buying a DSLR 📸!
            </p>
          </div>
        </div>
        {/* BOTTOM SIDE */}
        <div className="bio-bottom">
          <div className="read-me">
            <h2>README</h2>
            <div className="divider"></div>
          </div>
          <div className="read-me-paragraphs">
            <p>
              I'm a frontend developer with two years of professional experience
              in the field. My passion for creating visually appealing and
              user-friendly websites has driven me to specialize in HTML, CSS,
              JavaScript, and a range of other technologies
            </p>
            <p>
              I have hands-on experience with popular frameworks like React and
              Angular, as well as libraries such as jQuery. I thrive on turning
              design concepts into interactive and responsive web interfaces,
              bringing ideas to life with precision and creativity.
              Additionally, I have gained valuable experience in the UI/UX
              industry, utilizing Figma to create detailed UI mockups that
              enhance the user experience.
            </p>
            <p>
              Throughout my career, I’ve also gained experience with the .NET
              framework, successfully accomplishing backend tasks. This backend
              expertise has significantly enhanced my frontend development
              skills by giving me a deeper understanding of how web applications
              function end-to-end. It has allowed me to write more efficient,
              scalable code and collaborate more effectively with backend teams.
            </p>
            <p>
              I'm excited to showcase my work and skills through my portfolio—so
              let's dive in!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
