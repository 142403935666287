export default function TestimonialUI(props) {
  return (
    <div className="testimonial">
      <img src={props.image} className="testimonial-image" alt={props.alt} />
      <p>{props.paragraph}</p>
      <h2>{props.name}</h2>
      <h4>{props.role}</h4>
      <i className="fa fa-quote-left quote"></i>
    </div>
  );
}
