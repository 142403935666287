import { useEffect, useState } from "react";
import Main from "./components/main/Main";
import { BounceLoader } from "react-spinners";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="loader">
          <BounceLoader color="#e45447" size={100} loading={loading} />
        </div>
      ) : (
        <Main />
      )}
    </div>
  );
}

export default App;
